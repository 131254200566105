//封装本地token的存和取
export const setToken = (token) => {
  localStorage.setItem("TOKEN", token);
};
export const getToken = () => {
  return localStorage.getItem("TOKEN");
};

//封装本地删除存储数据的方法
export const removeToken = () => {
  localStorage.removeItem("TOKEN");
};

export const setInfo = (info,data)=>{
  localStorage.setItem(info,data);
}

export const getInfo = (info)=>{
  return JSON.parse(localStorage.getItem(info));
}
export const removeInfo = (info)=>{
  localStorage.removeItem(info);
}