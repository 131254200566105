import { createApp } from 'vue'
import App from './App.vue'
import router from "./router";
import BottomTab from '@/components/BottomTab.vue'
import LoadingPage from '@/components/LoadingPage.vue'
import { createI18n } from 'vue-i18n';
import en from '@/locales/en.json';
import an from '@/locales/an.json';
const app = createApp(App)
const i18n = createI18n({
  legacy: false,
  locale: (localStorage.getItem('lang') == null || localStorage.getItem('lang') == 1)?'en':'an', // 设置默认语言
  messages: {
    en,
    an
  }
});
app.component('BottomTab', BottomTab)
app.component('LoadingPage', LoadingPage)
app.use(router).use(i18n).mount('#app')
